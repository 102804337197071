const styles = (theme) => {
  const { baseUnit } = theme;

  return {
    title: {
      marginBottom: baseUnit * 10,
    },
  };
};

export default styles;
