const styles = (theme) => {
  const { baseUnit, palette } = theme;

  return {
    root: {
      marginBottom: baseUnit * 4,
    },
    heading: {
      marginBottom: baseUnit * 3,
      '& strong': {
        fontWeight: 500,
      },
    },
    url: {
      display: 'inline-block',
      marginRight: baseUnit,
    },
    link: {
      color: palette.text.secondary,
      display: 'inline-block',
      textDecoration: 'none',
      '&:hover': {
        color: palette.primary.main,
      },
    },
  };
};

export default styles;
