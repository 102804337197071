import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

import Typography from 'components/ui/Atoms/Typography';
import useFormikExtensions from 'components/Forms/useFormikExtensions';
import TextInput from 'components/Forms/Inputs/TextInput';
import Button from 'components/Buttons/Button';
import styles from './EmailVerificationStyles';

const useStyles = makeStyles({ name: 'EmailVerificationForm' })(styles);

const EmailVerificationForm = (props) => {
  // Props
  const { title, text, isNewUser } = props;

  // Hooks
  const { classes } = useStyles();
  const {
    handleSubmit,
    isSubmitting,
    getFieldPropsWithError,
  } = useFormikExtensions();

  return (
    <form onSubmit={handleSubmit} data-cy="email-verification-form">
      <Typography
        variant="display4"
        component="h1"
        className={classes.heading}
      >
        {title}
      </Typography>
      <Typography
        variant="paragraph"
        className={classes.text}
      >
        {text}
      </Typography>
      <TextInput
        {...getFieldPropsWithError('email')}
        className={classes.input}
        id="email"
        label="Email"
        placeholder="name@company.com"
        type="email"
      />
      <div className={classes.submitPanel}>
        <Button
          disabled={isSubmitting}
          className={classes.submitButton}
          type="submit"
          data-cy="email-verification-email-submit"
        >
          Continue
        </Button>
        {!isNewUser && (
          <Typography variant="caption2" className={classes.submitText}>
            Sign in to an&nbsp;
            <Link
              to="/login"
              data-cy="email-verification-login-link"
              className={classes.link}
            >
              existing workspace
            </Link>
          </Typography>
        )}
      </div>
    </form>
  );
};

EmailVerificationForm.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isNewUser: PropTypes.bool.isRequired,
};

export default EmailVerificationForm;
