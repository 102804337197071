export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    const parsedState = JSON.parse(serializedState);

    return parsedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (entireState) => {
  try {
    const {
      user,
      ui: {
        projectsView: {
          collapsedFilters,
          showDataBar,
          view,
        },
        projectView: {
          clientPresentationMode,
          collapsed,
          expandedOutlines,
        },
        projectTimelineView: {
          collapsedOutlines,
          showTaskLateness,
          showTaskTitles,
          collapsed: collapsedItems,
          showTaskDependencies,
          showTaskBaseline,
          views,
        },
        projectTimelineOutlineColumns,
        startingLineView: {
          collapsedGroupTables,
          showInsights,
          tasksByDepartmentCountKey,
          tasksByDaysOfWeekCountKey,
        },
      },
    } = entireState.state;
    const state = { state: { user } };
    const serializedState = JSON.stringify(state);
    const serializedUI = JSON.stringify({
      projectsView: {
        collapsedFilters,
        showDataBar,
        view,
      },
      projectView: {
        clientPresentationMode,
        collapsed,
        expandedOutlines,
      },
      projectTimelineView: {
        collapsedOutlines,
        showTaskLateness,
        showTaskTitles,
        showTaskDependencies,
        showTaskBaseline,
        collapsed: collapsedItems,
        views,
      },
      projectTimelineOutlineColumns,
      startingLineView: {
        collapsedGroupTables,
        showInsights,
        tasksByDepartmentCountKey,
        tasksByDaysOfWeekCountKey,
      },
    });
    localStorage.setItem('state', serializedState);
    localStorage.setItem('ui', serializedUI);
  } catch (err) {
    // Ignore write errors.
  }
};

export const clearState = () => {
  try {
    return localStorage.clear();
  } catch (err) {
    return undefined;
  }
};
