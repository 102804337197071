import ERROR_CODES from 'constants/errorCodes';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Helper used to create our error object in the desired error shape
 *
 * @returns {Object} error
 * @returns {string} error.detail Main detail of the error
 * @returns {number || string} error.error_code error code
 * @returns {Object} error.errors key/value pair of field/array of strings with errors
*/
export const createError = (detail, error_code = null, errors = undefined) => ({
  detail,
  error_code,
  errors,
});

/**
 * Helper handles responses from the backend
 * Has to be used inside an async function.
 * @param response A response object from the backend
 * @param idealStatus The ideal or expected response status
 * @throws {Object} error
*/
export const handleResponseStatus = (response, idealStatus) => {
  if (response.status !== idealStatus) {
    // eslint-disable-next-line max-len
    const message = `You have a mismatched response. You expected ${idealStatus}, but received ${response.status}`;
    const status = response.statusText;
    const error = createError(message, status);

    datadogRum.addError(error);

    throw error;
  }
};

/**
 * Handles errors coming from the server
 * @param error An error coming back from the backend
 * @param actionString A string to show where the error is coming from
 * @returns {Object} error
*/
export const handleServerError = (error, actionString = '') => {
  // eslint-disable-next-line no-console
  console.warn(`Server Error in ${actionString}: `, error);

  if (!error) {
    datadogRum.addError(new Error(`Unknown Server Error in ${actionString}`));
    return createError('Unknown Error');
  }

  datadogRum.addError(new Error(`Server Error in ${actionString}`, { cause: error }));

  if (error.response) {
    const errorData = error.response.data;

    if (typeof errorData === 'object') {
      const errorObj = errorData?.errors || errorData;
      const errorCode = errorData?.error_code;
      const errorDetail = errorData?.detail || 'Something went wrong';

      return createError(errorDetail, errorCode, errorObj);
    }
  }

  if (error.detail) return error;
  if (error.message) return createError(error.message);
  if (!error.status) return createError('Network error');

  return createError(`Unknown Error: ${error}`);
};

/**
 * Updates a field error in the error errors object
 * @param fieldError Error to be added or updated to the error errors object.
 * @param error Error object to be updated.
 * @returns {Object} error
*/
export function updateFieldErrors(error, fieldErrors) {
  const { errors } = error;
  const updatedErrors = { ...errors, ...fieldErrors };

  return { ...error, errors: updatedErrors };
}

/**
 * Updates a field error in the error errors object based on a given error code
 * @param fieldError Error to be added or updated to the error errors object.
 * @param error Error object to be updated.
 * @returns {Object} error
*/
export function updateFieldErrorsBasedOnCode(error, errorCode, fieldErrors) {
  const { error_code } = error;

  return errorCode === error_code ? updateFieldErrors(error, fieldErrors) : error;
}

/**
 * Updates an error object with an email object in its errors object (field errors)
 * Helper can be used to modify the key of the error with the fieldName given.
 * If we pass a duplicateError or a differentOrgError messages, those can be used to
 * give use a custom field error message.
 * @param error error object.
 * @param fieldName Optional string - default to 'email'
 * @param duplicateError Optional new duplicate error message
 * @param differentOrgError Optional new user belongs to different org message
 * @returns {Object} error
*/
export function handleNewEmailError(error, fieldName, duplicateError, differentOrgError) {
  const { errors, error_code } = error;
  const { duplicateEntity, existingOrganization } = ERROR_CODES;

  if ('email' in errors) {
    let fieldError = { [fieldName]: errors.email };

    if (duplicateError && error_code === duplicateEntity) {
      fieldError = { [fieldName]: duplicateError };
      return updateFieldErrorsBasedOnCode(error, duplicateEntity, fieldError);
    }

    if (differentOrgError && error_code === existingOrganization) {
      fieldError = { [fieldName]: differentOrgError };
      return updateFieldErrorsBasedOnCode(error, existingOrganization, fieldError);
    }

    return updateFieldErrors(error, fieldError);
  }

  return error;
}

/**
 * log the error and send it to data dog
 * @param {object}
 */
const handleApolloError = (errorMessage) => {
  console.warn(errorMessage);
  datadogRum.addError(new Error(errorMessage));
};

/**
 * Handles error coming from apollo
 * @param {object}
 */
export const apolloLinkErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => handleApolloError(`[GraphQL error]: ${message}`));
  }

  if (networkError) handleApolloError(`[Network error]: ${networkError}`);
};
