const styles = (theme) => {
  const { baseUnit, palette, mixins } = theme;

  return {
    header: {
      marginBottom: baseUnit * 10,
    },
    magicLinkButton: {
      marginBottom: baseUnit * 6,
      maxWidth: 320,
      width: '100%',
    },
    ssoButton: {
      marginBottom: baseUnit * 2,
      maxWidth: 320,
      width: '100%',
    },
    divider: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: baseUnit * 6,
      textTransform: 'uppercase',
      '&:before, &:after': {
        background: palette.border.secondary,
        content: '""',
        height: 1,
        marginTop: -1,
        width: '45%',
      },
      '&:before': {
        marginRight: baseUnit * 2,
      },
      '&:after': {
        marginLeft: baseUnit * 2,
      },
    },
    content: {
      maxWidth: 320,
      margin: '0px auto',
    },
    fieldset: {
      border: 'none',
      margin: 0,
      padding: 0,
    },
    panel: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    rememberCheckbox: {
      margin: 0,
    },
    forgotPassword: {
      ...mixins.linkifyText,
    },
    error: {
      color: palette.error.main,
      marginBottom: baseUnit * 6,
      minHeight: 18,
    },
    submitButton: {
      width: '100%',
    },
  };
};

export default styles;
