const styles = (theme) => {
  const { baseUnit, palette, mixins } = theme;

  return {
    heading: {
      marginBottom: baseUnit * 4,
    },
    text: {
      marginBottom: baseUnit * 10,
    },
    input: {
      maxWidth: 320,
      margin: `0px auto ${baseUnit * 13}px`,
      textAlign: 'left',
    },
    submitPanel: {
      textAlign: 'center',
    },
    submitButton: {
      marginBottom: baseUnit * 6,
    },
    submitText: {
      color: palette.text.secondary,
    },
    link: {
      ...mixins.linkifyText,
    },
  };
};

export default styles;
