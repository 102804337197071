import { Routes, Route } from 'react-router-dom';

import ErrorPage from 'components/Error/ErrorPage';
import Signup from 'components/Forms/UserSetup/Signup';
import Login from 'components/Login';
import ForgotPassword from 'components/Forms/UserSetup/ForgotPassword';
import ResetPassword from 'components/Forms/UserSetup/ResetPassword';
import MagicLink from 'components/Forms/UserSetup/MagicLink';
// import WorkspaceSetup from 'components/Forms/UserSetup/WorkspaceSetup';
import EmailVerification from 'components/Forms/UserSetup/EmailVerification';
import InactiveUserPage from 'components/Error/InactiveUserPage';
import InactiveWorkspacePage from 'components/Error/InactiveWorkspacePage';
import LinkExpired from 'components/Forms/UserSetup/LinkExpired';

const SetupRoutes = () => (
  <Routes>
    <Route path="*" element={<ErrorPage error={404} />} />
    <Route path="setup" element={<Signup />} />
    <Route path="login" element={<Login />} />
    <Route path="inactive_user" element={<InactiveUserPage />} />
    <Route path="inactive_workspace" element={<InactiveWorkspacePage />} />
    <Route path="account/magic_link" element={<MagicLink />} />
    <Route path="account/link_expired" element={<LinkExpired />} />
    <Route path="account/reset_password" element={<ForgotPassword />} />
    <Route path="account/reset_password/confirm/:uuidb64/:token" element={<ResetPassword />} />
    <Route path="workspaces/find" element={<EmailVerification />} />
    {/* <Route path="workspaces/new" element={<EmailVerification />} />
    <Route path="workspaces/setup" element={<WorkspaceSetup />} /> */}
  </Routes>
);

export default SetupRoutes;
