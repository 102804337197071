import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import Typography from 'components/ui/Atoms/Typography';
import Button from 'components/Buttons/Button';
import useFormikExtensions from 'components/Forms/useFormikExtensions';
import LoginHeader from './LoginHeader';
import TextInput from '../../Inputs/TextInput';
import PasswordInput from '../../Inputs/PasswordInput';
import Checkbox from '../../Inputs/Checkbox';
import styles from './LoginStyles';

const useStyles = makeStyles({ name: 'LoginForm' })(styles);

const Form = (props) => {
  // Props
  const { formError = '', search = '' } = props;

  // Hooks
  const {
    getFieldProps,
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
    hasVisibleErrors,
    values,
  } = useFormikExtensions();

  const { classes } = useStyles();
  const { value: email } = getFieldProps('email');

  const nextParams = search.includes('?next=') ? search : '';

  return (
    <form onSubmit={handleSubmit} data-cy="login-form">
      <LoginHeader className={classes.header} />
      <Button
        className={classes.magicLinkButton}
        variant="ghost"
        component={Link}
        to={`/account/magic_link${nextParams}`}
        state={{ email }}
        data-cy="setup-login-magic-link"
      >
        Sign in with magic link
      </Button>
      <Typography
        variant="body3"
        component="span"
        className={classes.divider}
      >
        Or
      </Typography>
      <div className={classes.content}>
        <TextInput
          {...getFieldPropsWithError('email')}
          id="email"
          label="Email"
          placeholder="Email address"
          type="email"
        />
        <PasswordInput
          {...getFieldPropsWithError('password')}
          id="password"
          label="Password"
          placeholder="Enter your password"
        />
        <div className={classes.panel}>
          <Checkbox
            {...getFieldProps('remember')}
            checked={values.remember}
            className={classes.rememberCheckbox}
            id="remember"
            label="Remember me"
          />
          <Typography
            variant="body1"
            component={Link}
            to="/account/reset_password"
            className={classes.forgotPassword}
            data-cy="setup-login-request-password-reset"
          >
            Forgot your password?
          </Typography>
        </div>
        <Typography
          variant="body1"
          component="span"
          className={classes.error}
        >
          {formError}
        </Typography>
        <Button
          disabled={isSubmitting || hasVisibleErrors}
          className={classes.submitButton}
          type="submit"
          data-cy="setup-login-button"
        >
          { isSubmitting ? 'Signing in...' : 'Sign in' }
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  formError: PropTypes.string,
  search: PropTypes.string,
};

export default Form;
