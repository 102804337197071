const styles = (theme) => {
  const { baseUnit } = theme;

  return {
    button: {
      width: baseUnit * 8,
      height: baseUnit * 8,
    },
  };
};

export default styles;
