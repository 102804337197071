import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

import useQueryStringObject from 'hooks/useQueryStringObject';

import SetupLayout from 'components/Layouts/SetupLayout';
import Typography from 'components/ui/Atoms/Typography';
import Button from 'components/Buttons/Button';
import styles from './LinkExpiredStyles';

const useStyles = makeStyles({ name: 'LinkExpired' })(styles);

const LinkExpired = () => {
  const { classes } = useStyles();
  const { wrong_device: wrongDevice } = useQueryStringObject();

  return (
    <SetupLayout>
      {wrongDevice ? (
        <>
          <Typography variant="display4" component="h1" className={classes.title}>
            This link is not valid!
          </Typography>
          <Typography className={classes.title}>
            If you’ve requested a magic link, then please open the magic link
            in the same browser that you requested it from.
          </Typography>
        </>
      ) : (
        <Typography variant="display4" component="h1" className={classes.title}>
          This link is expired!
        </Typography>
      )}
      <Button
        component={Link}
        to="/login"
      >
        Return to sign in
      </Button>
    </SetupLayout>
  );
};

export default LinkExpired;
