const styles = (theme) => {
  const { baseUnit, mixins, palette } = theme;

  return {
    header: {
      marginBottom: baseUnit * 4,
    },
    workspaceFqdn: {
      marginBottom: baseUnit * 6,
    },
    descriptionSent: {
      marginBottom: baseUnit * 8,
    },
    input: {
      maxWidth: 320,
      margin: `0px auto ${baseUnit * 13}px`,
    },
    logInPanel: {
      textAlign: 'center',
      marginBottom: baseUnit * 6,
    },
    backToLogin: {
      color: palette.text.secondary,
    },
    submitButton: {
      marginBottom: baseUnit * 4,
    },
    link: {
      ...mixins.linkifyText,
    },
  };
};

export default styles;
