const styles = (theme) => {
  const { baseUnit } = theme;

  return {
    root: {
      maxWidth: 480,
      textAlign: 'center',
    },
    title: {
      marginBottom: baseUnit * 6,
    },
    icon: {
      height: 62,
      marginBottom: baseUnit * 6,
      opacity: 0.4,
      width: 62,
    },
    text: {
      lineHeight: 1.5,
      marginBottom: baseUnit * 8,
    },
  };
};

export default styles;
