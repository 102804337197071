/* global data */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { makeStyles } from 'tss-react/mui';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import { passwordResetEmail } from 'actions/Users';

import { missingString, invalidString } from 'constants/errorMessages';
import { AVOID_SPACES } from 'constants/regex';

import SetupLayout from 'components/Layouts/SetupLayout';
import Typography from 'components/ui/Atoms/Typography';
import Button from 'components/Buttons/Button';
import Form from './ForgotPasswordForm';
import styles from './ForgotPasswordStyles';

const loginSchema = yup.object().shape({
  email: yup.string()
    .trim()
    .matches(AVOID_SPACES, 'Emails should have no spaces')
    .required(missingString('email', true))
    .email(invalidString('email address', true)),
});

const useStyles = makeStyles({ name: 'ForgotPasswordContainer' })(styles);

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Var
  const { workspace } = data;
  const workspaceFqdn = workspace === null ? window.location.hostname : workspace.fqdn;

  // Func
  const handleFormSubmit = (values) => {
    dispatch(passwordResetEmail(values));
    setIsFormSubmitted(true);
  };

  return (
    <SetupLayout>
      {isFormSubmitted ? (
        <div>
          <Typography
            variant="display4"
            component="h1"
            className={classes.header}
          >
            Email sent!
          </Typography>
          <Typography
            variant="body1"
            color="inherit"
            className={classes.descriptionSent}
          >
            If there is a Baton account linked to this email address,
            we will deliver instructions to reset your password.
          </Typography>
          <div className={classes.logInPanel}>
            <Button
              className={classes.submitButton}
              component={Link}
              data-cy="back-to-login"
              to="/login"
            >
              Go to sign in
            </Button>
          </div>
          <Typography
            variant="caption2"
            className={classes.backToLogin}
          >
            Unsure if that email was correct?&nbsp;
            <a
              href="mailto:support@hellobaton.com"
              className={classes.link}
            >
              Click here for help.
            </a>
          </Typography>
        </div>
      ) : (
        <div className={classes.formContainer}>
          <Typography
            variant="display4"
            component="h1"
            className={classes.header}
          >
            Forgot password
          </Typography>
          <Typography
            variant="body1"
            color="inherit"
          >
            Enter the email address you use to sign in to
          </Typography>
          <Typography
            variant="body2"
            color="inherit"
            className={classes.workspaceFqdn}
          >
            {workspaceFqdn}
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            validationSchema={loginSchema}
            initialValues={{
              email: '',
            }}
          >
            <Form />
          </Formik>
          <Typography
            variant="caption2"
            className={classes.backToLogin}
          >
            Back to <Link to="/login" className={classes.link}>sign in</Link>
          </Typography>
        </div>
      )}
    </SetupLayout>
  );
};

export default ForgotPasswordContainer;
