const styles = (theme) => {
  const { baseUnit, mixins, palette } = theme;

  return {
    container: {
      ...mixins.flex('center', 'center'),
      flexDirection: 'column',
      minHeight: '100vh',
      padding: `${baseUnit * 10}px ${baseUnit * 2}px`,
      width: '100%',
    },
    background: {
      ...mixins.position('fixed', 0),
      paddingTop: 210,
      zIndex: 0,
    },
    wordmark: {
      ...mixins.position('fixed', baseUnit * 10, 'auto', 'auto', baseUnit * 10),
      color: palette.primary.main,
    },
    contentRoot: {
      background: palette.background.default,
      border: `1px solid ${palette.border.secondary}`,
      borderRadius: 4,
      boxShadow: '0px 0px 4px 2px rgba(51, 51, 51, 0.08)',
      height: 'auto',
      maxWidth: 556,
      padding: `${baseUnit * 10}px ${baseUnit * 13}px`,
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      zIndex: 1,
    },
    helpButton: {
      background: palette.primary.main,
      borderRadius: '50%',
      bottom: baseUnit * 4,
      position: 'fixed',
      right: baseUnit * 2,
      '&:hover': {
        background: palette.primary.main,
      },
    },
  };
};

export default styles;
