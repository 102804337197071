import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import Button from 'components/Buttons/Button';
import Typography from 'components/ui/Atoms/Typography';
import useFormikExtensions from 'components/Forms/useFormikExtensions';
import InputLabel from 'components/Forms/Inputs/InputLabel';
import TextInput from 'components/Forms/Inputs/TextInput';
import styles from './WorkspaceLoginStyles';

const useStyles = makeStyles({ name: 'WorkspaceLoginForm' })(styles);

const Form = () => {
  // Hooks
  const {
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
  } = useFormikExtensions();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit} data-cy="workspace-login-form">
      <Typography
        variant="display4"
        component="h1"
        className={classes.heading}
      >
        Sign in to your workspace
      </Typography>
      <InputLabel component="span">
        Enter your company&#39;s Baton URL
      </InputLabel>
      <div className={classes.domain}>
        <div className={classes.input}>
          <TextInput
            {...getFieldPropsWithError('subdomain')}
            aria-label="Enter your company&#39;s Baton URL"
            id="subdomain"
          />
        </div>
        <Typography
          variant="h2"
          component="span"
          className={classes.rootDomain}
        >
          .hellobaton.com
        </Typography>
      </div>
      <div className={classes.submitPanel}>
        <Button
          disabled={isSubmitting}
          className={classes.submitButton}
          type="submit"
          data-cy="workspace-login-button"
        >
          Continue
        </Button>
        <Typography
          variant="caption2"
          className={classes.findText}
        >
          Don&#39;t know your workspace URL?&nbsp;
          <Link
            className={classes.link}
            data-cy="workspace-login-find-link"
            to="/workspaces/find"
          >
            Click here to find it.
          </Link>
        </Typography>
      </div>
    </form>
  );
};

export default Form;
