const styles = (theme) => {
  const { baseUnit, mixins } = theme;

  return {
    header: {
      marginBottom: baseUnit * 4,
    },
    workspaceFqdn: {
      marginBottom: baseUnit * 6,
    },
    passwordChangeConfirmation: {
      marginBottom: baseUnit * 10,
    },
    input: {
      maxWidth: 320,
      margin: `0px auto ${baseUnit * 13}px`,
    },
    passwordButtonContainer: {
      display: 'flex',
      paddingTop: baseUnit * 8,
      justifyContent: 'center',
    },
    link: {
      ...mixins.linkifyText,
    },
  };
};

export default styles;
