/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { pointerWithin, rectIntersection } from '@dnd-kit/core';

/**
 * Animate layout changes for sorting lists via DND kit
 * @param {*} event
 * @returns
 */
export const animateLayoutChanges = (event) => {
  const { isSorting, wasSorting } = event;

  return (isSorting || wasSorting) ? defaultAnimateLayoutChanges(event) : true;
};

/**
 * Custom collision detection algorithm that uses pointerWithin if we are using
 * a pointer device.
 * @param args
 * @returns
 */
export const customCollisionDetectionAlgorithm = (args) => {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = pointerWithin(args);

  // Collision detection algorithms return an array of collisions
  return (pointerCollisions.length > 0)
    ? pointerCollisions
    // If there are no collisions with the pointer, return rectangle intersections
    : rectIntersection(args);
};

/**
 * Simple sleep promise
 * @param {number} ms
 * @returns {Promise}
 */
// https://github.com/standard/standard/issues/1555
// eslint-disable-next-line no-promise-executor-return
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
