const styles = (theme) => {
  const { baseUnit, mixins, palette } = theme;

  return {
    text: {
      marginBottom: baseUnit * 10,
    },
    confirmText: {
      background: palette.status.blue[4],
      borderRadius: 4,
      margin: `0px auto ${baseUnit * 6}px`,
      maxWidth: 320,
      padding: baseUnit * 2,
    },
    input: {
      maxWidth: 320,
      margin: `0px auto ${baseUnit * 10}px`,
      textAlign: 'left',
    },
    submitButton: {
      marginBottom: baseUnit * 4,
    },
    loginButton: {
      marginBottom: baseUnit * 10,
    },
    loginLink: {
      color: palette.text.secondary,
      textDecoration: 'none',
      '&:hover': {
        color: palette.primary.main,
      },
    },
    panel: {
      borderTop: `1px solid ${palette.border.secondary}`,
      maxWidth: 400,
      margin: `0px auto ${baseUnit * 4}px`,
      display: 'flex',
      justifyContent: 'space-around',
      paddingTop: baseUnit * 4,
    },
    link: {
      ...mixins.linkifyText,
    },
  };
};

export default styles;
