const styles = (theme) => {
  const { baseUnit, palette } = theme;

  return {
    heading: {
      color: palette.primary.main,
      marginBottom: baseUnit * 3,
      '& strong': {
        fontWeight: 500,
      },
    },
    url: {
      marginBottom: baseUnit * 13,
    },
    splitInputContainer: {
      display: 'flex',
    },
    splitInput: {
      flex: 1,
      marginRight: baseUnit * 2,
      '&:last-child': {
        marginRight: 0,
      },
    },
    submitPanel: {
      textAlign: 'center',
    },
    error: {
      color: palette.error.main,
      marginBottom: baseUnit * 8,
      minHeight: 18,
    },
    submitButton: {
      marginBottom: baseUnit * 5,
    },
    footer: {
      color: palette.text.secondary,
      lineHeight: '19px',
      '& a': {
        color: palette.primary.main,
        textDecoration: 'none',
      },
    },
  };
};

export default styles;
