export const ACCOUNT_TYPES: Record<string, string> = {
  1000: 'WORKSPACE_ADMIN',
  750: 'INTERNAL',
  500: 'EXTERNAL',
  250: 'THIRD_PARTY',
  0: 'UNKNOWN',
};

export const READABLE_ACCOUNT_TYPES: Record<string, string> = {
  1000: 'Admin',
  750: 'Internal',
  500: 'External',
  250: '3rd Party',
  0: 'Unknown',
};

export const userAccountType: Record<string, number> = Object.keys(ACCOUNT_TYPES)
  .reduce((obj, key) => ({ ...obj, [ACCOUNT_TYPES[key]]: parseInt(key, 10) }), {});

export type AccountTypeOption = {
  value: string;
  label: string;
};

// As we open up 3rd party, this is the place to add the feature flag
//    to add the option everywhere
export const accountTypeOptions: AccountTypeOption[] = Object.keys(READABLE_ACCOUNT_TYPES)
  .filter(acct => parseInt(acct, 10) >= 500) // ws admin, internal, external
  .map(acct => ({ value: acct, label: READABLE_ACCOUNT_TYPES[acct] }));

export default ACCOUNT_TYPES;
