import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const VisibilityOff = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M21.31 7s-3.154 5.04-9 5.04c-5.847 0-9-5.04-9-5.04"
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12.31 13.54v3M16.31 13.04l1.5 2.599M20 11.28l2.121 2.12M6.81 15.639l1.5-2.598M3 13.4l2.121-2.12"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

VisibilityOff.displayName = 'VisibilityOff';

export default VisibilityOff;
