import { Link } from 'react-router-dom';

import theme from '../theme';

export const missingString = (string: string, startsWithVowel?: boolean) => `Please enter a${startsWithVowel ? 'n' : ''} ${string}`;

export const missingNumber = 'Plase enter a number';

export const missingTitle = (subject: string) => `Please name this ${subject}`;

export const notSelected = (string: string, startsWithVowel?: boolean) => `Please select a${startsWithVowel ? 'n' : ''} ${string}`;

export const invalidString = (string: string) => `Something looks wrong with this ${string}`;

export const existingEmail = 'An account with this email already exists';

export const missingFeedbackRating = 'A rating is required';

export const duplicateErrorLink = (email: string) => (
  <Link target="_blank" to={`/app/workspace/members?search=${email}`} style={{ color: theme.palette.primary.main }}>Workspace Management</Link>
);

export const duplicateUserError = (email: string, company: string, isClient = false) => {
  let error = 'A user with this email address already exists.';
  if (company) {
    error += ` They are with ${company}.`;
  }
  if (email && !isClient) {
    return <span>{error} Please visit the {duplicateErrorLink(email)} page.</span>;
  }
  return error;
};

export const differentOrgError = () => 'This user belongs to a different company.';

export const clientChampExistsDifferentOrgError = (company: string) => {
  let error = 'The champion or lead should belong to the client company.';
  if (company) {
    error += ` They are with ${company}.`;
  }
  return error;
};

export const vendorPMExistsdifferentOrgError = (company: string) => {
  let error = 'The project lead should belong to the vendor company.';
  if (company) {
    error += ` They are with ${company}.`;
  }
  return error;
};
