import { makeStyles } from 'tss-react/mui';

import SetupLayout from 'components/Layouts/SetupLayout';
import Button from 'components/Buttons/Button';
import Typography from 'components/ui/Atoms/Typography';
import LockClosed from 'components/ui/Atoms/Icons/LockClosed';
import styles from './InactiveUserPageStyles';

const useStyles = makeStyles({ name: 'InactiveUserPage' })(styles);

const InactiveUserPage = () => {
  const { classes } = useStyles();

  return (
    <SetupLayout>
      <div className={classes.root} data-cy="inactive-user-page">
        <Typography className={classes.title} component="h1" variant="h1">
          Account Currently Disabled
        </Typography>
        <LockClosed className={classes.icon} />
        <Typography className={classes.text} component="p" variant="h3">
          We apologize for the inconvenience. <br />
          Contact your vendor lead for any questions or concerns.
        </Typography>
        <Button
          href="/login"
          className={classes.button}
        >
          Back to sign in page
        </Button>
      </div>
    </SetupLayout>
  );
};

export default InactiveUserPage;
