import { makeStyles } from 'tss-react/mui';

import useFormikExtensions from 'components/Forms/useFormikExtensions';
import PasswordInputWithStrength from 'components/Forms/Inputs/PasswordInput/PasswordInputWithStrength';
import Button from 'components/Buttons/Button';
import styles from './ResetPasswordStyles';

const useStyles = makeStyles({ name: 'ResetPasswordForm' })(styles);

const Form = () => {
  // Hooks
  const {
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
    hasVisibleErrors,
  } = useFormikExtensions();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <PasswordInputWithStrength
        {...getFieldPropsWithError('password')}
        className={classes.input}
        id="password"
        label="New password"
        placeholder=""
      />
      <Button
        disabled={isSubmitting || hasVisibleErrors}
        className={classes.submitButton}
        type="submit"
      >
        Change password
      </Button>
    </form>
  );
};

export default Form;
