import { pickBy } from 'lodash';
import * as yup from 'yup';
import queryString from 'query-string';

import { invalidString, missingString } from 'constants/errorMessages';
import PROJECT_USER_ROLES, {
  DEPRECATED_PROJECT_STAKEHOLDER_ROLES,
  DEPRECATED_PROJECT_USER_ROLES,
  PROJECT_VENDOR_ROLES,
  READABLE_PROJECT_USER_ROLES,
} from 'constants/projectUserRoles';
import unknownUser from 'constants/unknownUser';
import { userAccountType } from 'constants/userAccountTypes';
import { AVOID_SPACES } from 'constants/regex';

import type { CompanyNode, ProjectNode, ProjectUserNode, ProjectRateType, UserNode } from 'graphql/__generated__/graphql';

import { alphaNumericSort, pluralize, validateRedirectUrl } from 'helpers/utils';

type ThirdPartyIntegrationAppUser = {
  name: string
};

type NotificationPreferences = {
  recipient_account_types: number[]
  group: {
    label: string;
  }
}[];

type UserTableRow = {
  column: string,
  value: string | number,
}[];

type ExtendedProjectNode = ProjectNode & {
  users?: { [key: string]: ProjectUserNode };
};

type ExtendedUserNode = UserNode & {
  projects?: ProjectNode[];
  workspace?: {
    url: string
  };
};

type QueryString = {
  next: string
};

/*
  WORKSPACE USER HELPERS
*/
export const isInternal = ({ account_type }: UserNode) => {
  const internalAccountTypes = [userAccountType.WORKSPACE_ADMIN, userAccountType.INTERNAL];
  return internalAccountTypes.includes(account_type);
};

export const isExternal = ({ account_type }: UserNode) => account_type === userAccountType.EXTERNAL;

export const isWorkspaceAdmin = ({ account_type }: UserNode) => (
  account_type ? account_type === userAccountType.WORKSPACE_ADMIN : false
);

export const getWorkspaceAdminUsers = (users: { [key: string]: UserNode }) => (
  pickBy(users, user => isWorkspaceAdmin(user))
);

/*
  this is used to determine if the user has access to the Django admin
  site and is unrelated to admin account_type (aka workspace admin)
*/
export const isStaff = ({ is_staff }: UserNode) => is_staff;

export const getCompanyUsers = (
  users: { [key: string]: UserNode },
  companyId: CompanyNode['id'],
) => (
  pickBy(users, user => user.company.id === companyId)
);

export const getUserStrings = (user: Partial<UserNode>) => {
  const userData = user || { ...unknownUser, email: '' };
  const { first_name: first, last_name: last, email } = userData;

  let name: string = email || '';
  let avatarName = email ? email.charAt(0) : '';

  if (first && last) {
    name = `${first} ${last}`;
    avatarName = first.charAt(0) + last.charAt(0);
  } else if (first || last) {
    name = first || last;
    avatarName = first ? first.charAt(0) : last.charAt(0);
  }

  return { name, avatarName };
};

export const getJobTitleAndCompany = (user: UserNode) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { job_title, company } = user;
  const title = job_title || '';
  const companyName = company?.name || '';
  const dividerDot = companyName && title ? ' • ' : '';

  return `${companyName}${dividerDot}${title}`;
};

/**
 * Return name/avatar name for an app integration user i.e. Jira
 */
export const getAppUserStrings = (appUser: ThirdPartyIntegrationAppUser) => {
  const { name = '' } = appUser || {};
  const avatarName = name ? name.substr(0, 2) : '';

  return { name, avatarName };
};

export const segmentNotificationPreferencesByGroup = (
  user: UserNode,
  notificationPreferences: NotificationPreferences,
) => {
  const segmented: { group: { label: string }; preferences: NotificationPreferences }[] = [];
  const labelIndexMap: { [key: string]: number } = {};
  let idx = 0;

  Object.values(notificationPreferences).map((pref) => {
    if (pref.recipient_account_types.includes(user.account_type)) {
      if (pref.group.label in labelIndexMap) {
        segmented[labelIndexMap[pref.group.label]].preferences.push(pref);
      } else {
        labelIndexMap[pref.group.label] = idx;
        segmented[idx] = {
          group: pref.group,
          preferences: [pref],
        };

        idx += 1;
      }
    }

    return pref;
  });

  // Ensure the order of the groups is the same as the one in Workspace Settings
  const sortOrder = ['Summary Reports', 'Task Updates', 'Project Updates', 'Time Tracking'];
  if (sortOrder.every(label => label in labelIndexMap)) {
    return segmented.sort(
      (a, b) => sortOrder.indexOf(a.group.label) - sortOrder.indexOf(b.group.label),
    );
  }
  return segmented.reverse();
};

export const sortUserTableByColumn = (
  table: UserTableRow[],
  sortColumn: string,
  sortDirection: string,
) => (
  table.sort((firstRow, secondRow) => {
    const firstColumn = firstRow.find(cell => cell.column === sortColumn);
    const secondColumn = secondRow.find(cell => cell.column === sortColumn);

    return alphaNumericSort(firstColumn.value, secondColumn.value, sortDirection);
  })
);

export const getUserSearchResults = (
  searchQuery: string,
  users: { [key: string]: UserNode },
) => {
  const userValues = Object.values(users);
  const query = searchQuery.toLowerCase();

  return userValues.filter((user) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { last_name, first_name, email } = user;
    const name = `${first_name} ${last_name}`;

    return [name, email]
      .map(param => param && param.toLowerCase().includes(query))
      .some(bool => bool);
  });
};

export const getUserSelectLabel = (user: UserNode) => `${user.first_name} ${user.last_name} (${user.email})`;

export const getUserSelectOptions = (
  users: { [key: string]: UserNode },
  userValueKey = 'id',
) => (
  // Always return the user id as well.
  Object.values(users)
    .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
    .map(user => ({
      user,
      id: user.id,
      value: user[userValueKey] as string | number,
      label: getUserSelectLabel(user),
    })) || []
);

export const getUserFromSelectValue = (
  users: { [key: string]: UserNode },
  value: string | number,
) => (
  Object.values(users).find(user => user.id === value || user.email === value)
);

export const isValidEmail = (email: UserNode['email']) => {
  const emailSchema = yup.string().email();

  return emailSchema.isValidSync(email);
};

export const getUserSelectValueSchema = (required = false) => {
  const userSelectSchema = yup.lazy((value) => {
    switch (typeof value) {
      case 'number':
        return yup.number();
      case 'string':
        return yup.string()
          .trim()
          .matches(AVOID_SPACES, 'Emails should have no spaces')
          .email(invalidString('email'))
          .required(missingString('email address', true));

      default:
        return required ? yup.mixed().required(missingString('email address', true)) : yup.mixed().nullable();
    }
  });

  return userSelectSchema;
};

export const getUserWithTaskCountFromNode = (node: UserNode) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { assigned_tasks, ...user } = node || {};
  const numOfTasks = assigned_tasks?.edgeCount ?? 0;
  const taskCount = `(${numOfTasks} ${(pluralize(numOfTasks, 'task', 'tasks'))})`;

  return { ...user, taskCount };
};

export const redirectUserSignup = (
  user: ExtendedUserNode,
  navigate: (setupLink: string) => void,
) => {
  const { workspace } = user;
  const qs = queryString.parse(window.location.search) as QueryString;
  const userLink = '/app/projects';

  if (workspace && 'url' in workspace) {
    const path = validateRedirectUrl(qs.next) ? qs.next : userLink;

    window.location.href = `${workspace.url}${path}`;
  } else {
    navigate('/workspaces/setup');
  }
};

/*
  PROJECT USER HELPERS
*/

export const getProjectUser = (project: ExtendedProjectNode, userId: UserNode['id']) => (
  Object.values(project.users).find(projectUser => projectUser.user === userId)
);

export const getProjectRate = (
  projectRates: { [key: string]: ProjectRateType },
  projectRateId: ProjectRateType['id'],
) => (
  Object.values(projectRates).find(projectRate => projectRate.id === projectRateId)
);

export const getProjectUserRoleFromBooleans = (projectUser: ProjectUserNode) => {
  const keys = Object.keys(projectUser);
  const stakeholderRoles = keys
    .filter(key => DEPRECATED_PROJECT_STAKEHOLDER_ROLES.some(role => role === key));
  const projectRoles = keys
    .filter(key => DEPRECATED_PROJECT_USER_ROLES.some(role => role === key));
  const stakeholderRole = stakeholderRoles.find(role => projectUser[role] === true);
  const role = projectRoles.find(userRole => projectUser[userRole] === true);

  // users can have both a stakeholder role or contributor role - we only need one
  // so stakeholder takes precendence
  console.warn('DEPRECATION WARNING: We should now be using the role attribute in the project user object.');
  return (PROJECT_USER_ROLES[stakeholderRole || role] as number);
};

export const getProjectUserRole = (projectUser: ProjectUserNode) => {
  const hasRole = 'role' in projectUser && Object.values(PROJECT_USER_ROLES).includes(projectUser.role);

  return hasRole ? projectUser.role : getProjectUserRoleFromBooleans(projectUser);
};

export const getReadableRoleWithCompanyName = (role: ProjectUserNode['role'], project: ProjectNode) => {
  const company = PROJECT_VENDOR_ROLES.includes(role)
    ? project.vendor.name
    : project.client?.name || 'Client';

  return (role === undefined || company === undefined)
    ? 'Contributor'
    : `${company} ${READABLE_PROJECT_USER_ROLES[role] as string}`;
};

export const getReadableProjectUserRole = (project: ProjectNode, projectUser: ProjectUserNode) => {
  const role = getProjectUserRole(projectUser);

  return getReadableRoleWithCompanyName(role, project);
};

export const getUserReadableProjectUserRole = (
  project: ProjectNode,
  user: UserNode,

) => {
  const projectUser = getProjectUser(project, user.id);

  if (!projectUser) {
    return '';
  }

  return getReadableProjectUserRole(project, projectUser);
};

const segmentUsersByRole = (
  project: ProjectNode,
  users: { [key: string]: UserNode } | UserNode[],
) => {
  const projectLeads: UserNode[] = [];
  const clientChampions: UserNode[] = [];
  const vendorStakeholders: UserNode[] = [];
  const clientStakeholders: UserNode[] = [];
  const contributors: UserNode[] = [];

  Object.values(users).forEach((user) => {
    const projectUser = getProjectUser(project, user.id);
    const role = projectUser && getProjectUserRole(projectUser);

    if (role) {
      switch (role) {
        case PROJECT_USER_ROLES.vendor_pm:
          projectLeads.push(user);
          break;
        case PROJECT_USER_ROLES.client_champ:
          clientChampions.push(user);
          break;
        case PROJECT_USER_ROLES.vendor_stakeholder:
          vendorStakeholders.push(user);
          break;
        case PROJECT_USER_ROLES.client_stakeholder:
          clientStakeholders.push(user);
          break;
        default:
          contributors.push(user);
      }
    }
  });

  return [
    ...projectLeads,
    ...clientChampions,
    ...vendorStakeholders,
    ...clientStakeholders,
    ...contributors,
  ];
};

export const getProjectUsers = (
  users: { [key: string]: ExtendedUserNode },
  projectId: ProjectNode['id'],
) => pickBy(users, user => (
  user.projects.length > 0 && user.projects.includes(parseInt(projectId, 10))
));

export const getProjectTeam = (
  users: { [key: string]: UserNode },
  project: ProjectNode,
) => segmentUsersByRole(project, users);

export const getVendorProjectUsers = (
  project: ProjectNode,
  users: { [key: string]: UserNode },
) => {
  const projectUsers = getProjectUsers(users, project.id);
  const vendorUsers = Object.values(projectUsers).filter(user => isInternal(user));

  return segmentUsersByRole(project, vendorUsers);
};

export const getClientProjectUsers = (
  project: ProjectNode,
  users: { [key: string]: UserNode },
) => {
  const projectUsers = getProjectUsers(users, project.id);
  const clientUsers = Object.values(projectUsers).filter(user => isExternal(user));

  return segmentUsersByRole(project, clientUsers);
};

export const isProjectClientChamp = (
  project: ProjectNode,
  userId: UserNode['id'],
) => {
  const projectUser = getProjectUser(project, userId);
  const role = projectUser && getProjectUserRole(projectUser);

  return projectUser ? role === PROJECT_USER_ROLES.client_champ : false;
};

export const getProjectClientChamp = (
  project: ProjectNode,
  users: { [key: string]: UserNode } | UserNode[],
) => {
  if (project && users) {
    return Object.values(users).find(user => isProjectClientChamp(project, user.id));
  }

  return null;
};

export const isProjectLead = (
  project: ProjectNode,
  userId: UserNode['id'],
) => {
  const projectUser = getProjectUser(project, userId);
  const role = projectUser && getProjectUserRole(projectUser);

  return projectUser ? role === PROJECT_USER_ROLES.vendor_pm : false;
};

export const getProjectLead = (
  project: ProjectNode,
  users: { [key: string]: UserNode } | UserNode[],
) => {
  if (project && users) {
    return Object.values(users).find(user => isProjectLead(project, user.id));
  }

  return null;
};
