import { makeStyles } from 'tss-react/mui';

import SetupLayout from 'components/Layouts/SetupLayout';
import Typography from 'components/ui/Atoms/Typography';
import LockClosed from 'components/ui/Atoms/Icons/LockClosed';
import styles from './InactiveWorkspacePageStyles';

const useStyles = makeStyles({ name: 'InactiveUserPage' })(styles);

const InactiveUserPage = () => {
  const { classes } = useStyles();

  return (
    <SetupLayout>
      <div className={classes.root} data-cy="inactive-user-page">
        <Typography className={classes.title} component="h1" variant="h1">
          Workspace Currently Disabled
        </Typography>
        <LockClosed className={classes.icon} />
        <Typography className={classes.text} component="p" variant="h3">
          If this is in error, please contact <a href="mailto:support@hellobaton.com">support@hellobaton.com</a>
        </Typography>
      </div>
    </SetupLayout>
  );
};

export default InactiveUserPage;
