const styles = (theme) => {
  const { palette, baseUnit, mixins } = theme;

  return {
    heading: {
      marginBottom: baseUnit * 13,
    },
    label: {
      marginBottom: baseUnit * 4,
    },
    domain: {
      display: 'flex',
      marginBottom: baseUnit * 8,
    },
    input: {
      flex: 1,
      marginRight: baseUnit * 3,
    },
    rootDomain: {
      fontWeight: 300,
      marginTop: baseUnit * 3,
    },
    submitPanel: {
      textAlign: 'center',
    },
    submitButton: {
      marginBottom: baseUnit * 4,
    },
    findText: {
      color: palette.text.secondary,
    },
    link: {
      ...mixins.linkifyText,
    },
  };
};

export default styles;
