import { handleResponseStatus, handleServerError } from 'helpers/errors';
import { cloudinaryUpload } from 'requests/uploads';
import {
  updateMe,
  createVisualCue,
  verifyUser,
  sendFindWorkspacesEmail,
  getNotificationPreferences,
  putNotificationPreference,
  getNotificationPreferenceCadence,
  putNotificationPreferenceCadence,
} from 'requests/users';

/*
  Exclude the password as information update
*/
export const userUpdateSuccess = ({ password, ...data }) => ({
  type: 'USER_UPDATE_SUCCESS',
  data,
});

export const visualCueCreateSuccess = visualCue => ({
  type: 'VISUAL_CUE_CREATE_SUCCESS',
  visualCue,
});

export const orientationOff = () => ({
  type: 'TURN_ORIENTATION_OFF',
});

export const orientationOn = () => ({
  type: 'TURN_ORIENTATION_ON',
});

export const userUpdate = data => async (dispatch) => {
  try {
    const response = await updateMe(data);

    handleResponseStatus(response, 200);
    dispatch(userUpdateSuccess(response.data));

    return response.data;
  } catch (error) {
    throw handleServerError(error, 'userUpdate');
  }
};

export const visualCueCreate = visual_cue_id => async (dispatch, getState) => {
  try {
    const { user } = getState();
    const { visual_cues } = user;

    if (!visual_cues[visual_cue_id]) {
      const response = await createVisualCue({ user: user.id, visual_cue_id });

      handleResponseStatus(response, 201);
      dispatch(visualCueCreateSuccess(response.data));

      return response.data;
    }
    return visual_cues[visual_cue_id];
  } catch (error) {
    throw handleServerError(error, 'visualCueCreate Action');
  }
};

export const visualCuesCreate = visualCueIds => async (dispatch) => {
  try {
    await dispatch(orientationOff());
    await Promise.all(visualCueIds.map(async (visualCueId) => {
      await dispatch(visualCueCreate(visualCueId));
    }));
    await dispatch(orientationOn());
  } catch (error) {
    throw handleServerError(error, 'visualCuesCreate Action');
  }
};

export const verifyNewUserEmail = email => async () => {
  try {
    const response = await verifyUser({ email });

    handleResponseStatus(response, 204);
  } catch (error) {
    throw handleServerError(error, 'verifyUser Action');
  }
};

export const verifyUserEmail = email => async () => {
  try {
    const response = await sendFindWorkspacesEmail({ email });

    handleResponseStatus(response, 204);
  } catch (error) {
    throw handleServerError(error, 'verifyUserEmail Action');
  }
};

export const uploadUserAvatar = file => async (dispatch) => {
  try {
    const response = await cloudinaryUpload(file);
    const avatarUrl = response?.data?.secure_url;

    dispatch(userUpdate({ avatar_url: avatarUrl }));
  } catch (error) {
    throw handleServerError(error, 'uploadUserAvatar Action');
  }
};

export const notificationPreferencesFetch = () => async () => {
  try {
    const response = await getNotificationPreferences();

    handleResponseStatus(response, 200);
    return response.data.results;
  } catch (error) {
    throw handleServerError(error, 'notificationPreferencesFetch Action');
  }
};

export const notificationPreferenceUpdate = (key, enabled) => async () => {
  try {
    const response = await putNotificationPreference(key, enabled);

    handleResponseStatus(response, 200);

    return response.data;
  } catch (error) {
    throw handleServerError(error, 'notificationPreferenceUpdate Action');
  }
};

export const notificationPreferenceCadenceFetch = notificationPreferenceKey => async () => {
  try {
    const response = await getNotificationPreferenceCadence(notificationPreferenceKey);

    handleResponseStatus(response, 200);
    return response.data;
  } catch (error) {
    throw handleServerError(error, 'notificationPreferenceCadenceFetch Action');
  }
};

export const notificationPreferenceCadenceUpdate = (key, data) => async () => {
  try {
    const response = await putNotificationPreferenceCadence(key, data);

    handleResponseStatus(response, 200);

    return response.data;
  } catch (error) {
    throw handleServerError(error, 'notificationPreferenceCadenceUpdate Action');
  }
};
