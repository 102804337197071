/* global data */
import SetupLayout from 'components/Layouts/SetupLayout';
import Login from 'components/Forms/UserSetup/Login';
import WorkspaceLogin from 'components/Forms/UserSetup/WorkspaceLogin';

/**
 * Based on the data object found in the root html we take the user to the correct login form
 *
 * @return {ReactElement} User Role Project (e.g. Vendor, Client, Clientdoer)
 */

const LoginSwitcher = (props) => {
  const hasWorkspace = Boolean(data.workspace);
  let LoginComponent = Login;

  if (!hasWorkspace) LoginComponent = WorkspaceLogin;

  return <SetupLayout><LoginComponent {...props} /></SetupLayout>;
};

export default LoginSwitcher;
