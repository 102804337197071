import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import HelpButton from 'components/Buttons/HelpButton';
import Wordmark from 'components/Illustrations/Wordmark';
import TrackAndField from 'components/Illustrations/TrackAndField';
import styles from './SetupLayoutStyles';

const useStyles = makeStyles({ name: 'SetupLayout' })(styles);

function SetupLayout({ children }) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <TrackAndField
        className={classes.background}
      />
      <Wordmark className={classes.wordmark} />
      <section className={classes.contentRoot}>
        {children}
      </section>
      <HelpButton className={classes.helpButton} />
    </div>
  );
}

SetupLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SetupLayout;
