import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

const CircleQuestion = forwardRef<SVGSVGElement, Props>((props, ref) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle
      opacity="0.6"
      cx="20"
      cy="20"
      r="20"
      fill="white"
    />
    <path
      d="M18.7678 23.0469C18.7782 22.4271 18.8485 21.9375 18.9788 21.5781C19.109 21.2188 19.3746 20.8203 19.7756 20.3828L20.7991 19.3281C21.2366 18.8333 21.4553 18.3021 21.4553 17.7344C21.4553 17.1875 21.3121 16.7604 21.0256 16.4531C20.7392 16.1406 20.3225 15.9844 19.7756 15.9844C19.2444 15.9844 18.8173 16.125 18.4944 16.4062C18.1715 16.6875 18.01 17.0651 18.01 17.5391H16.5647C16.5751 16.6953 16.8746 16.0156 17.4631 15.5C18.0569 14.9792 18.8277 14.7188 19.7756 14.7188C20.76 14.7188 21.5256 14.9844 22.0725 15.5156C22.6246 16.0417 22.9006 16.7656 22.9006 17.6875C22.9006 18.599 22.4788 19.4974 21.635 20.3828L20.7834 21.2266C20.4032 21.6484 20.2131 22.2552 20.2131 23.0469H18.7678ZM18.7053 25.5234C18.7053 25.2891 18.7756 25.0938 18.9163 24.9375C19.0621 24.776 19.2756 24.6953 19.5569 24.6953C19.8381 24.6953 20.0517 24.776 20.1975 24.9375C20.3433 25.0938 20.4163 25.2891 20.4163 25.5234C20.4163 25.7578 20.3433 25.9531 20.1975 26.1094C20.0517 26.2604 19.8381 26.3359 19.5569 26.3359C19.2756 26.3359 19.0621 26.2604 18.9163 26.1094C18.7756 25.9531 18.7053 25.7578 18.7053 25.5234Z"
      fill="#333333"
    />
  </svg>
));

CircleQuestion.displayName = 'CircleQuestion';

export default CircleQuestion;
