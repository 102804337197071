/* global data */
import { useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles } from 'tss-react/mui';

import { passwordReset } from 'actions/Users';
import { MIN_PASSWORD_LENGTH } from 'helpers/forms';
import { missingString } from 'constants/errorMessages';

import SetupLayout from 'components/Layouts/SetupLayout';
import Typography from 'components/ui/Atoms/Typography';
import Button from 'components/Buttons/Button';
import Form from './ResetPasswordForm';
import styles from './ResetPasswordStyles';

const formSchema = yup.object().shape({
  password: yup.string().required(missingString('password'))
    .min(MIN_PASSWORD_LENGTH, 'Passwords must be at least 8 characters long.'),
});

const useStyles = makeStyles({ name: 'ResetPasswordContainer' })(styles);

const ResetPasswordContainer = () => {
  // Hooks
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Var
  const { workspace, password_reset_link_expired: passwordResetLinkExpired } = data;
  const workspaceFqdn = workspace?.fqdn ?? window.location.hostname;

  // Func
  const onSubmit = async (values, { setErrors }) => {
    try {
      await dispatch(passwordReset(pathname, values));
      setIsFormSubmitted(true);
    } catch (error) {
      const errors = Object.values(error.errors);
      const errorMsg = errors.length ? errors[0] : 'Please try a different password';

      setErrors({ password: errorMsg });
    }
  };

  return (
    <SetupLayout>
      {isFormSubmitted ? (
        <div>
          <Typography
            variant="display4"
            component="h1"
            className={classes.header}
          >
            Password changed!
          </Typography>
          <Typography
            variant="caption2"
            color="inherit"
            className={classes.passwordChangeConfirmation}
          >
            Your new password for <strong>{workspaceFqdn}</strong> is ready to go.
          </Typography>
          <Button
            className={classes.submitButton}
            component={Link}
            to="/login"
            data-cy="back-to-login"
          >
            Go to sign in
          </Button>
        </div>
      ) : (
        <div className={classes.formContainer}>
          <Typography
            variant="display4"
            component="h1"
            className={classes.header}
          >
            {passwordResetLinkExpired ? 'Link expired' : 'Reset password'}
          </Typography>
          {passwordResetLinkExpired ? (
            <>
              <Typography variant="body1">
                This password reset link has expired. Please request a new one.
              </Typography>
              <div className={classes.passwordButtonContainer}>
                <Button
                  to="/account/reset_password"
                  className={classes.resetPasswordButton}
                  component={Link}
                >
                  Reset Password
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                color="inherit"
              >
                Let’s get you back on track. Enter a new password for
              </Typography>
              <Typography
                variant="body2"
                color="inherit"
                className={classes.workspaceFqdn}
              >
                {workspaceFqdn}
              </Typography>
              <Formik
                onSubmit={onSubmit}
                validationSchema={formSchema}
                initialValues={{
                  password: undefined,
                }}
              >
                <Form />
              </Formik>
            </>
          )}
        </div>
      )}
    </SetupLayout>
  );
};

export default ResetPasswordContainer;
