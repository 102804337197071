import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

import Button from 'components/Buttons/Button';
import Typography from 'components/ui/Atoms/Typography';
import TextInput from 'components/Forms/Inputs/TextInput';
import useFormikExtensions from 'components/Forms/useFormikExtensions';
import styles from './MagicLinkStyles';

const useStyles = makeStyles({ name: 'ForgotPasswordForm' })(styles);

const Form = () => {
  // Hooks
  const {
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
    hasVisibleErrors,
  } = useFormikExtensions();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Typography
        variant="h3"
        className={classes.text}
      >
        We’ll send you a quick email to confirm your address.
      </Typography>
      <TextInput
        {...getFieldPropsWithError('email')}
        className={classes.input}
        id="email"
        label="Email"
        placeholder="Email address"
        type="email"
      />
      <Button
        disabled={isSubmitting || hasVisibleErrors}
        className={classes.submitButton}
        type="submit"
      >
        Get my link
      </Button>
      <Typography
        variant="caption2"
        className={classes.loginLink}
        component={Link}
        to="/login"
      >
        Sign in using password instead
      </Typography>
    </form>
  );
};

export default Form;
