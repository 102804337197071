/* global data */
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';

import { userLogin } from 'actions/Users';

import { missingString, invalidString } from 'constants/errorMessages';
import { AVOID_SPACES } from 'constants/regex';

import { sleep } from 'helpers/ui';
import { trackEvent } from 'helpers/analytics';
import { clearState } from 'helpers/localStorage';
import { validateRedirectUrl } from 'helpers/utils';
import { checkUserSSOStatus } from 'requests/users';

import LoginForm from './LoginForm';
import SSOForm from './LoginSSOForm';

const emailSchema = {
  email: yup.string()
    .trim()
    .matches(AVOID_SPACES, 'Emails should have no spaces')
    .required(missingString('email', true))
    .email(invalidString('email address', true)),
};

const loginSchema = yup.object().shape({
  ...emailSchema,
  password: yup.string().required(missingString('password')).min(8, 'Passwords must be at least 8 characters long.'),
  set_cookies: yup.bool().required().default(true),
  remember: yup.bool(),
});

const loginSSOSchema = yup.object().shape(emailSchema);
const { workspace } = data;

const LoginContainer = () => {
  // Props
  const { search } = window.location;

  // Hooks
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [useSSO, setUseSSO] = useState(workspace?.sso_connected);
  const [email, setEmail] = useState('');
  const ssoInitialValues = useMemo(() => ({ email }), [email]);
  const loginInitialValues = useMemo(() => ({
    email,
    password: '',
    remember: true,
    set_cookies: true,
  }), [email]);

  // Fun
  const login = async (values, actions) => {
    const { setSubmitting } = actions;
    const qs = queryString.parse(search);

    setError(null);

    try {
      const response = await dispatch(userLogin(values));
      const { redirect, user_id } = response.data;
      const href = validateRedirectUrl(qs.next) ? qs.next : redirect;

      clearState();
      trackEvent('Login', {}, user_id);
      await sleep(1000); // Should prob do a feedback here.
      window.location.href = href; // using location to force a refresh
    } catch (error) {
      setError('Oops! You entered an invalid email or password.');
      setSubmitting(false);
    }
  };

  const loginSSO = async (values) => {
    const { data: { redirect } } = await checkUserSSOStatus(values);
    if (redirect) {
      window.location = '/auth/sso/login/';
    } else {
      setUseSSO(false);
      setEmail(values.email);
    }
  };

  if (useSSO) {
    return (
      <Formik
        key="login-sso"
        onSubmit={loginSSO}
        validationSchema={loginSSOSchema}
        initialValues={ssoInitialValues}
      >
        <SSOForm formError={error} />
      </Formik>
    );
  }

  return (
    <Formik
      key={`login-${email}`}
      onSubmit={login}
      validationSchema={loginSchema}
      initialValues={loginInitialValues}
    >
      <LoginForm search={search} formError={error} />
    </Formik>
  );
};

export default LoginContainer;
