/* global data */
import axios from 'axios';
import queryString from 'query-string';

const instance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: params => queryString.stringify(params, { arrayFormat: 'brackets' }),
});

instance.interceptors.request.use(
  (config) => {
    const { csrf_token, user } = data;

    if (csrf_token != null) {
    config.headers['X-CSRFToken'] = csrf_token;  // eslint-disable-line
    }

    if (user) {
    config.headers['X-Baton-User-Id'] = user.id; // eslint-disable-line
    }

    return config;
  },
  err => Promise.reject(err),
);

export default instance;

export const migrateResponseConfig = {
  headers: {
    Accept: 'application/vnd.baton.standard+json',
  },
};
