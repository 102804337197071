// *********************************************************
// IMPORTANT: THESE CONSTANTS NEED TO BE IN SYNC WITH
//  web.models.workspace.PRICING_PLAN_CHOICES
// *********************************************************

export const PRICING_PLAN_STANDARDIZE = 1000;
export const PRICING_PLAN_ACCELERATE = 2000;
export const PRICING_PLAN_ENTERPRISE = 3000;

export const PRICING_PLANS_READABLE = {
  [PRICING_PLAN_STANDARDIZE]: 'Standardize',
  [PRICING_PLAN_ACCELERATE]: 'Accelerate',
  [PRICING_PLAN_ENTERPRISE]: 'Enterprise',
};

export const PRICING_PLAN_LINK = 'https://hellobaton.com/pricing/';

export const FEATURE_TIME_TRACKING = 'timeTracking';
export const FEATURE_RESOURCES = 'resources';
export const FEATURE_INTEGRATIONS = 'integrations';
export const FEATURE_JIRA_INTEGRATION = 'jiraIntegration';
export const FEATURE_SALESFORCE_INTEGRATION = 'salesforceIntegration';
export const FEATURE_API = 'api';
export const FEATURE_DASHBOARD_CUSTOMIZATION = 'dashboardCustomization';

export const STANDARDIZE_RESTRICTION_LIST = [
  FEATURE_TIME_TRACKING,
  FEATURE_RESOURCES,
  FEATURE_INTEGRATIONS,
  FEATURE_API,
  FEATURE_DASHBOARD_CUSTOMIZATION,
];

export const ACCELERATE_RESTRICTION_LIST = [
  FEATURE_JIRA_INTEGRATION,
  FEATURE_SALESFORCE_INTEGRATION,
  FEATURE_API,
  FEATURE_DASHBOARD_CUSTOMIZATION,
];
