/* global config */
import { useCallback } from 'react';
import { Formik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';

import { missingString } from 'constants/errorMessages';
import Form from './WorkspaceLoginForm';

const workspaceLoginSchema = yup.object().shape({
  subdomain: yup.string().required(missingString('url')),
});

const validateWorkspaceUrl = async (url) => {
  const urlSchema = yup.string().url();
  const error = { subdomain: 'Workspace URL must be between 3 and 24 characters and contain only letters, numbers, and hyphens.' };

  if (!urlSchema.isValidSync(url)) {
    throw error;
  }

  return true;
};

const validateWorkspaceAccount = async (url) => {
  const error = { subdomain: 'This workspace does not exist' };

  try {
    const response = await fetch(url, { method: 'HEAD', 'Content-Type': 'application/json' });

    if (!response || response.status >= 400) throw error;

    return true;
  } catch (err) {
    throw error;
  }
};

const WorkspaceLoginContainer = () => {
  const goToWorkspace = useCallback(async (values, actions) => {
    const { subdomain } = values;
    const { setErrors, setSubmitting, resetForm } = actions;
    const { protocol, search } = window.location;
    const query = queryString.parse(search);
    const domain = config.SITE_DOMAIN.replace('app', subdomain);
    const url = `${protocol}//${domain}${query.next || ''}/login`;

    try {
      await validateWorkspaceUrl(url);
      await validateWorkspaceAccount(url);
      resetForm();
      setSubmitting(false);
      window.location.href = url;
    } catch (error) {
      setErrors(error);
      setSubmitting(false);
    }
  }, []);

  return (
    <Formik
      onSubmit={goToWorkspace}
      validationSchema={workspaceLoginSchema}
      initialValues={{
        subdomain: '',
      }}
    >
      <Form />
    </Formik>
  );
};

export default WorkspaceLoginContainer;
