import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

import useFormikExtensions from 'components/Forms/useFormikExtensions';
import Button from 'components/Buttons/Button';
import Typography from 'components/ui/Atoms/Typography';
import TextInput from '../../Inputs/TextInput';
import styles from './MagicLinkStyles';

const useStyles = makeStyles({ name: 'ForgotPasswordForm' })(styles);

const Form = (props) => {
  // Props
  const { requestEmail } = props;

  // Hooks
  const {
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
  } = useFormikExtensions();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="caption1" className={classes.confirmText}>
        We sent a magic link and temporary sign-in code to <br />
        <strong>{requestEmail}</strong>. Please check your inbox.
      </Typography>
      <TextInput
        {...getFieldPropsWithError('code')}
        className={classes.input}
        id="code"
        label="Sign-in code"
        placeholder="Paste code"
      />
      <Button
        disabled={isSubmitting}
        className={classes.loginButton}
        type="submit"
        onClick={handleSubmit}
      >
        Sign in with code
      </Button>
      <div className={classes.panel}>
        <Button
          type="button"
          variant="ghost"
          onClick={() => window.location.reload()}
        >
          Get another magic link
        </Button>
        <Button
          type="button"
          variant="ghost"
          component={Link}
          to="/login"
        >
          Sign in with password
        </Button>
      </div>
      <Typography
        variant="caption2"
        className={classes.footer}
      >
        Unsure if that email was correct?&nbsp;
        <a
          href="mailto:support@hellobaton.com"
          className={classes.link}
        >
          Click here for help.
        </a>
      </Typography>
    </form>
  );
};

Form.propTypes = {
  requestEmail: PropTypes.string.isRequired,
};

export default Form;
