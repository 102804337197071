/* global data */
import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { showConfetti } from 'actions/ui';
import { userUpdate } from 'actions/User';
import { isExternal, redirectUserSignup } from 'helpers/users';
import { trackEvent } from 'helpers/analytics';

import theme from 'theme';
import { missingString } from 'constants/errorMessages';

import SetupLayout from 'components/Layouts/SetupLayout';
import SignupForm from './SignupForm';

const signupSchema = yup.object().shape({
  first_name: yup.string().required(missingString('first name')),
  last_name: yup.string().required(missingString('last name')),
  password: yup.string().required(missingString('password'))
    .min(8, 'Passwords must be at least 8 characters long.'),
});

const getTitle = (vendor, user) => {
  let title = 'Great, let’s finish setting up your profile';

  if (vendor) {
    title = isExternal(user)
      ? <>Collaborate with <strong>{vendor.name}</strong> on your new project</>
      : <>Join the workspace for<br /><strong>{vendor.name}</strong></>;
  }

  return title;
};

const colors = theme.palette.confettiColors.slice(-2);

const Signup = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const vendor = user?.workspace?.vendor;
  const title = useMemo(() => getTitle(vendor, user), [vendor, user]);
  const [error, setError] = useState(null);

  // Var
  const domainName = vendor ? vendor?.fqdn || window.location.hostname : null;
  const submitText = vendor ? 'Create account' : 'Continue';

  // Func
  const redirectUser = () => {
    redirectUserSignup(user, navigate);
  };

  const saveUser = async (values, actions) => {
    const { setErrors, setSubmitting } = actions;
    const confettiProps = { duration: 2500, colors };
    const { user } = data;

    if (!user) {
      setError('User data temporarily unavailable');
      return;
    }

    try {
      await dispatch(userUpdate(values));
      trackEvent('User Signup');
      dispatch(showConfetti(() => {
        setSubmitting(false);
        redirectUser();
      }, confettiProps));
    } catch (error) {
      setErrors(error.errors || error.response.data);
      setSubmitting(false);
    }
  };

  return (
    <SetupLayout>
      <Formik
        onSubmit={saveUser}
        validationSchema={signupSchema}
        initialValues={{
          first_name: user?.first_name ?? '',
          last_name: user?.last_name ?? '',
          password: '',
          job_title: user?.job_title ?? '',
        }}
      >
        <SignupForm
          title={title}
          fqdn={domainName}
          formError={error}
          submitText={submitText}
        />
      </Formik>
    </SetupLayout>
  );
};

export default Signup;
