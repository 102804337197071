/* global config */
import axios from 'axios';

import type { AxiosProgressEvent, AxiosResponse } from 'axios';

import request from './setup';

type Config = {
  CLOUDINARY: { API_KEY: string, URL: string }
};

export type AWSSignatureOptions = {
  temp?: boolean // Used for temp files in forms.
  editor?: boolean // Used for rich text editor image uploads.
  form_link_url?: string // Used for anonymous form submissions.
};

export type AWSSignatureResponse = {
  url: string
  fields: {
    key: string
    AWSAccessKeyId: string
    signature: string
    policy: string
  }
  public_url: string
};

type AWSUploadOptions = {
  onUploadSignature?: (data: AWSSignatureResponse) => void
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
};

export const signUpload = params => request.post('/v1/upload-signer', params);

export const awsSignUpload = (params): Promise<{ data: AWSSignatureResponse }> => request.post('/v1/upload-aws-signer', params);

const headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'multipart/form-data',
};

const getCloudinarySigningParams = (timestamp: number) => ({
  params: {
    upload_preset: 'ml_default',
    tags: 'browser_upload',
    timestamp,
  },
});

export const cloudinaryUpload = async (file: File) => {
  const timestamp = Date.now();
  const signingParams = getCloudinarySigningParams(timestamp);
  const signatureResponse = await signUpload(signingParams);
  const { CLOUDINARY } = config as Config;
  const { signature } = signatureResponse.data as { signature: string };
  const data = new FormData();

  data.append('upload_preset', 'ml_default');
  data.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
  data.append('timestamp', `${timestamp}`);
  data.append('file', file);
  data.append('signature', signature);
  data.append('api_key', CLOUDINARY.API_KEY);

  const response = await axios({ method: 'post', url: CLOUDINARY.URL, data, headers });

  return response;
};

export const awsUpload = async (
  file: File,
  signatureOptions: AWSSignatureOptions = {},
  uploadOptions: AWSUploadOptions = {},
): Promise<AxiosResponse & { signatureResponseData: AWSSignatureResponse }> => {
  const { onUploadSignature, ...otherUploadOptions } = uploadOptions;
  const { name, type } = file;
  const signatureRequest = { filename: name, content_type: type, ...signatureOptions };
  const { data: signatureData } = await awsSignUpload(signatureRequest);
  const { url, fields } = signatureData;
  const data = new FormData();

  if (onUploadSignature) onUploadSignature(signatureData);

  data.append('key', fields.key);
  data.append('AWSAccessKeyId', fields.AWSAccessKeyId);
  data.append('signature', fields.signature);
  data.append('policy', fields.policy);
  data.append('file', file);

  const response = await axios({ method: 'post', url, data, headers, ...otherUploadOptions });

  return { ...response, signatureResponseData: signatureData };
};
