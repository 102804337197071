import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { verifyUserEmail, verifyNewUserEmail } from 'actions/User';

import { missingString, invalidString } from 'constants/errorMessages';
import { AVOID_SPACES } from 'constants/regex';

import { trackEvent } from 'helpers/analytics';

import SetupLayout from 'components/Layouts/SetupLayout';
import EmailVerificationForm from './EmailVerificationForm';
import Confirmation from './Confirmation';

const emailSchema = yup.object().shape({
  email: yup.string()
    .trim()
    .matches(AVOID_SPACES, 'Emails should have no spaces')
    .required(missingString('email', true))
    .email(invalidString('email address', true)),
});

const EmailVerification = () => {
  // Hooks
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(undefined);

  // Variables
  const isNewUser = pathname.includes('new');
  let title = 'Find your workspace';
  let text = 'We’ll send you an email to confirm your address and find existing workspaces you have joined.';

  if (isNewUser) {
    title = 'Welcome to Baton!';
    text = 'You’re at the starting line of a new experience for project implementation. Let’s hit the ground running.';
  }

  const handleEmailSubmit = async (values, actions) => {
    const { setSubmitting, setErrors } = actions;
    const { email } = values;

    try {
      if (isNewUser) {
        await dispatch(verifyNewUserEmail(email));
        trackEvent('Workspace New User Verification Sent', { email });
      } else {
        trackEvent('Workspace Find Verification Sent', { email });
        await dispatch(verifyUserEmail(email));
      }
      setSubmitting(false);
      setShowConfirmation(true);
      setVerifiedEmail(email);
    } catch (errors) {
      setErrors(errors);
      setSubmitting(false);
    }
  };

  return (
    <SetupLayout>
      {!showConfirmation ? (
        <Formik
          onSubmit={handleEmailSubmit}
          validationSchema={emailSchema}
          initialValues={{
            email: '',
          }}
        >
          <EmailVerificationForm
            title={title}
            text={text}
            isNewUser={isNewUser}
          />
        </Formik>
      ) : (
        <Confirmation email={verifiedEmail} isNewUser={isNewUser} />
      )}
    </SetupLayout>
  );
};

export default EmailVerification;
