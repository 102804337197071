/* global data */
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Typography from 'components/ui/Atoms/Typography';
import styles from './LoginHeaderStyles';

const useStyles = makeStyles({ name: 'LoginHeader' })(styles);

const LoginHeader = (props) => {
  // Props
  const { className = '' } = props;
  const { host, protocol, hostname } = window.location;

  // Hooks
  const { classes, cx } = useStyles();

  // Vars
  const fqdn = data.worspace?.fqdn ?? hostname;
  const parts = host.split('.');
  const appIdx = parts.length === 3 ? 0 : 1;

  parts[appIdx] = 'app';

  return (
    <div className={cx(classes.root, className)}>
      <Typography
        variant="display4"
        component="h1"
        className={classes.heading}
      >
        Sign in to <strong>Baton</strong>
      </Typography>
      <div>
        <Typography
          variant="body2"
          component="span"
          className={classes.url}
        >
          {fqdn}
        </Typography>
        <Typography
          variant="caption2"
          component="a"
          href={`${protocol}//${parts.join('.')}/login`}
          className={classes.link}
        >
          Not your workspace?
        </Typography>
      </div>
    </div>
  );
};

LoginHeader.propTypes = {
  className: PropTypes.string,
};

export default LoginHeader;
