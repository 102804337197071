import { makeStyles } from 'tss-react/mui';

import useFormikExtensions from 'components/Forms/useFormikExtensions';
import TextInput from 'components/Forms/Inputs/TextInput';
import Button from 'components/Buttons/Button';
import styles from './ForgotPasswordStyles';

const useStyles = makeStyles({ name: 'ForgotPasswordForm' })(styles);

const Form = () => {
  // Hooks
  const {
    getFieldPropsWithError,
    handleSubmit,
    isSubmitting,
    hasVisibleErrors,
  } = useFormikExtensions();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        {...getFieldPropsWithError('email')}
        className={classes.input}
        id="email"
        label="Email"
        placeholder="Email address"
        type="email"
      />
      <Button
        disabled={isSubmitting || hasVisibleErrors}
        className={classes.submitButton}
        type="submit"
      >
        Get reset link
      </Button>
    </form>
  );
};

export default Form;
