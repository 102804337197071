import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';

import { magicLinkEmail, userLogin } from 'actions/Users';

import { missingString, invalidString } from 'constants/errorMessages';
import { AVOID_SPACES } from 'constants/regex';

import { trackEvent } from 'helpers/analytics';
import { clearState } from 'helpers/localStorage';
import { validateRedirectUrl } from 'helpers/utils';

import SetupLayout from 'components/Layouts/SetupLayout';
import { useLocation } from 'react-router-dom';
import LoginHeader from '../Login/LoginHeader';
import RequestMagicLinkForm from './RequestMagicLinkForm';
import CodeLoginForm from './CodeLoginForm';

const requestLinkSchema = yup.object().shape({
  email: yup.string()
    .trim()
    .matches(AVOID_SPACES, 'Emails should have no spaces')
    .required(missingString('email', true))
    .email(invalidString('email address', true)),
});

const codeLoginSchema = yup.object().shape({
  code: yup.string().required(missingString('code')),
});

const MagicLinkContainer = () => {
  const { search } = window.location;
  const qs = queryString.parse(search);

  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const { email = '' } = location.state;

  const [requestEmail, setRequestEmail] = useState(null);

  // Fun
  const handleLinkRequest = async (email, setErrors) => {
    try {
      await dispatch(magicLinkEmail({ email }));
      setRequestEmail(email);
      trackEvent('Magic link requested');
    } catch {
      setErrors({ email: 'Oops something went wrong, please try again!' });
    }
  };

  const handleCodeLogin = async (values, setErrors) => {
    try {
      const response = await dispatch(userLogin(values));
      const { redirect, user_id } = response.data;
      const href = validateRedirectUrl(qs.next) ? qs.next : redirect;

      clearState();
      trackEvent('Login', { magic_link: true }, user_id);
      window.location.href = href; // using location to force a refresh
    } catch {
      setErrors({ code: 'Oops! You entered an invalid login code.' });
    }
  };

  const handleSubmit = async (values, actions) => {
    const { email, code } = values;
    const { setSubmitting, setErrors } = actions;

    if (code && email) {
      await handleCodeLogin(values, setErrors);
    } else {
      await handleLinkRequest(email, setErrors);
    }

    setSubmitting(false);
  };

  return (
    <SetupLayout>
      <LoginHeader />
      <Formik
        onSubmit={handleSubmit}
        validationSchema={requestEmail ? codeLoginSchema : requestLinkSchema}
        initialValues={{
          email,
          code: '',
          remember: true,
          set_cookies: true,
        }}
      >
        {requestEmail ? (
          <CodeLoginForm requestEmail={requestEmail} />
        ) : (
          <RequestMagicLinkForm />
        )}
      </Formik>
    </SetupLayout>
  );
};

export default MagicLinkContainer;
