import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Close = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
        d="m7 7 10 10m0-10L7 17"
      />
    </svg>
  );
});

Close.displayName = 'Close';

export default Close;
