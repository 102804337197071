import queryString from 'query-string';
import Fuse from 'fuse.js';

/**
 * Check the value being passed as a redirect via qs is valid.
 * Checking it starts with a / ensures it's relative, and will stay on the current domain.
 * Ensuring it doesn't start with // blocks protocol-less URLs
 * In short, *must* be in the form /path/to/destination
 * @param {string} value
 *
 * @return {string | boolean}
 */
export const validateRedirectUrl = (value: string): string | boolean => (value && value.startsWith('/') && !value.startsWith('//') ? value : false);

export const pluralize = (count: string | number, singular: string, plural: string) => {
  const value = typeof count === 'string' ? parseInt(count, 10) : count;

  return value === 1 ? singular : plural;
};

export const fuzzySearch = (query: string, list: string[], options: object) => {
  const fuse = new Fuse(list, options);

  return fuse.search(query);
};

export const extractJiraIssueFromUrl = (url: string): string | null => {
  if (url) {
    const regex = /https:\/\/.+\.atlassian\.net.+(?:browse\/|selectedIssue=)([a-z][a-z_]+-[0-9]+)/i;
    const match = url.match(regex);

    return match ? match[1] : null;
  }

  return null;
};

export const alphaNumericSort = (
  firstValue: number | string,
  secondValue: number | string,
  sortDirection = 'desc',
) => {
  const order = sortDirection === 'asc' ? [firstValue, secondValue] : [secondValue, firstValue];

  if (!order[0] && order[0] !== 0) return -1;
  if (!order[1] && order[1] !== 0) return 1;

  return order[0].toString().localeCompare(order[1].toString(), 'en', { numeric: true });
};

export const capitalizeFirstLetter = (string: string): string => (
  string.charAt(0).toUpperCase() + string.slice(1)
);

export const getPercentage = (total: number, num: number): number => (num / total) * 100;

export const getRoundedPercentage = (total: number, num: number): number => (
  Math.round(getPercentage(total, num))
);

/**
 * Trim a string to the nearest word of a given length
 * If the resulting string is shorter than minLen, then a hard trim is done at trimLen
 * @param {string} trimStr the string to be trimmed
 * @param {number} trimLen the nominal length to trim the string to
 * @param {number} minLen the minimum length of the desired string
 *
 * @return {string}
 */
export const trimToWord = (trimStr: string, trimLen: number, minLen: number): string => {
  // just return the string if it's already short enough
  if (trimStr.length < trimLen) return trimStr;

  // trim the string to the nominal length
  const trimmedStr = trimStr.substring(0, trimLen);

  // trim that string down to the last whole word
  const trimmedToWord = trimmedStr
    .substring(0, trimmedStr.lastIndexOf(' '))
    .replace(/\s+$/, '');
  // if the trimmed length is too short
  if (trimmedToWord.length < minLen) {
    // return the normally-trimmed string
    return trimmedStr;
  }
  // otherwise return the string trimmed to the word
  return trimmedToWord;
};

export const attachQueriesToPathname = (url: string, query = {}, skipEmptyString = false) => (
  queryString.stringifyUrl({ url, query }, { skipNull: true, skipEmptyString })
);

export const parseIfJson = (possibleJson: JSON | unknown) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return typeof possibleJson === 'string' ? JSON.parse(possibleJson) : possibleJson;
  } catch {
    return possibleJson;
  }
};

export const waitForElement = (selector: string) => new Promise((resolve) => {
  if (document.querySelector(selector)) {
    resolve(document.querySelector(selector));
  }

  const observer = new MutationObserver(() => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
      observer.disconnect();
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
});

export const isNumber = (value: unknown) => {
  const possibleNumber = Number(value);

  return !Number.isNaN(possibleNumber);
};

export const checkIsAppIframed = () => window.parent !== window.self;
export const sendPostMessageToClientSuccess = (message: {
  type: string;
  payload: unknown;
}) => {
  if (!message.type) throw new Error('Please provide type for message');

  window.parent.postMessage(message, '*');
};
